import React from "react";

function AdminItem({ admin, onEdit }) {
    const handleEditClick = () => {
        onEdit(admin);
    };

    const handleDeleteClick = () => {
        // Delete logic here (update the dummy list or later on make a DELETE API request)
    };

    return (
        <div className="admin-card">
            <img src={admin.image} alt={admin.name} className="admin-image"/>
            <div className="admin-info">
                <span>{admin.name}</span>
                <div className="admin-actions">
                    <i className="fas fa-edit" onClick={handleEditClick}></i>
                    <i className="fas fa-trash-alt" onClick={handleDeleteClick}></i>
                </div>
            </div>
        </div>
    );
}

export default AdminItem;
