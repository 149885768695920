import React, { useState } from "react";
import StudentCard from "../student_segment_screen/screen_components/StudentCard";
import PrintableSection from "./screen_components/PrintableSection";

function AllStudentsComponent({ handleBackButtonClick, studentData }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    classLevel: "",
    classArm: "",
    gender: "",
  });

  const filteredStudents = studentData.filter((student) => {
    const fullName =
      `${student.lastName} ${student.firstName} ${student.middleName}`.toLowerCase();
    const searchQueryLower = searchQuery.toLowerCase();

    if (fullName.includes(searchQueryLower)) {
      if (
        (filters.classLevel === "" ||
          filters.classLevel === student.classLevel) &&
        (filters.classArm === "" || filters.classArm === student.classArm) &&
        (filters.gender === "" || filters.gender === student.gender)
      ) {
        return true;
      }
    }

    return false;
  });

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const [showPrintableSection, setShowPrintableSection] = useState(false);

  const handlePrintClick = () => {
    setShowPrintableSection(true);
  };

  return (
    <div className="component-screen">
      <div className="header-comp">
        <div onClick={handleBackButtonClick} className="back-btn-sg">
          <i className="fas fa-arrow-left"></i> Back
        </div>
        <div className="header-txt">
          <h3>All Students</h3>
        </div>
      </div>
      <div className="component-content">
        <div className="search-bar">
          <i className="fas fa-search mx-2"></i>
          <input
            type="text"
            placeholder="Search for a student"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div className="filters-section">
          <label className="filter-label">Class Level:</label>
          <select
            name="classLevel"
            value={filters.classLevel}
            onChange={handleFilterChange}
            className="filter-select"
          >
            <option value="">All</option>
            <option value="JSS1">JSS1</option>
            <option value="JSS2">JSS2</option>
            <option value="JSS3">JSS3</option>
            <option value="SSS1">SSS1</option>
            <option value="SSS2">SSS2</option>
            <option value="SSS3">SSS3</option>
          </select>

          <label className="filter-label"> Class Arm:</label>
          <select
            name="classArm"
            value={filters.classArm}
            onChange={handleFilterChange}
            className="filter-select"
          >
            <option value="">All</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
          </select>

          <label className="filter-label">Gender:</label>
          <select
            name="gender"
            value={filters.gender}
            onChange={handleFilterChange}
            className="filter-select"
          >
            <option value="">All</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>

        <div className="filters-display">
          {Object.entries(filters).map(([key, value]) => {
            if (value) {
              return (
                <span key={key} className="filter-tag">
                  {key}: {value}
                </span>
              );
            }
            return null;
          })}
        </div>
        <div className="student-list-container">
          <div className="student-list">
            {filteredStudents.map((student) => (
              <StudentCard key={student.id} student={student} />
            ))}
          </div>
        </div>
        <div className="print-button" onClick={handlePrintClick}>
          <div className="print-icon">
            <i className="fas fa-print"></i> Print
          </div>
        </div>
        {showPrintableSection && (
          <PrintableSection
            filteredStudents={filteredStudents}
            filters={filters}
            onClose={() => setShowPrintableSection(false)}
          />
        )}
      </div>
    </div>
  );
}

export default AllStudentsComponent;
