import React from "react";

function SubjectListPopup({ subjects, onClose }) {
  return (
    <div className="subject-list-popup">
      <div className="subject-list-header">
        <h3>Subjects</h3>
        <i className="fas fa-times" onClick={onClose}></i>
      </div>
      <div className="subject-list">
        {subjects.length > 0 ? (
          subjects.map((subject) => (
            <div key={subject.id} className="subject-item">
              <p className="subject-title">{subject.title}</p>
              <p className="subject-class">{subject.classLevel}</p>
            </div>
          ))
        ) : (
          <p>No subjects yet</p>
        )}
      </div>
    </div>
  );
}

export default SubjectListPopup;
