import React from "react";

function DatePicker({ selectedDate, handleDateChange }) {
  const handleChange = (event) => {
    const date = event.target.value;
    handleDateChange(date);
  };

  return (
    <div className="date-picker">
      <input
        type="date"
        id="dateOfBirth"
        name="dateOfBirth"
        value={selectedDate}
        onChange={handleChange}
        required
      />
    </div>
  );
}

export default DatePicker;
