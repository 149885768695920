import React from "react";
import { Bar } from "react-chartjs-2";

function AgeChartBar({ studentData }) {
  const averageAgeByClassLevel = studentData.reduce((accumulator, student) => {
    if (!accumulator[student.classLevel]) {
      accumulator[student.classLevel] = {
        totalAge: 0,
        count: 0,
      };
    }
    accumulator[student.classLevel].totalAge += student.age;
    accumulator[student.classLevel].count++;
    return accumulator;
  }, {});

  Object.keys(averageAgeByClassLevel).forEach((classLevel) => {
    const { totalAge, count } = averageAgeByClassLevel[classLevel];
    averageAgeByClassLevel[classLevel] = Math.round(totalAge / count);
  });

  const classLevels = Object.keys(averageAgeByClassLevel);

  const ageChartData = {
    labels: classLevels,
    datasets: [
      {
        label: "Average Age",
        data: classLevels.map(
          (classLevel) => averageAgeByClassLevel[classLevel]
        ),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
    ],
  };

  return (
    <div className="chart">
      <h3 className="chart-title">Average Age by Class Levels</h3>
      <div className="chart-container-s">
        <Bar data={ageChartData} options={{ maintainAspectRatio: false }} />
      </div>
    </div>
  );
}

export default AgeChartBar;
