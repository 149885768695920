function generateRandomID() {
  return Math.random().toString(36).substr(2, 9);
}

const teachersData = [
  {
    id: generateRandomID(),
    firstname: "John",
    middlename: "Doe",
    lastname: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1990-05-15",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Jane",
    middlename: "Doe",
    lastname: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1992-08-25",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "English",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Literature",
        classLevel: "JSS2",
      },
      {
        id: generateRandomID(),
        title: "Linguistics",
        classLevel: "JSS2",
      },
    ],
  },

  {
    id: generateRandomID(),
    firstname: "David",
    middlename: "Smith",
    lastname: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1985-11-10",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "SSS1",
      },
      {
        id: generateRandomID(),
        title: "Futher Maths",
        classLevel: "SSS1",
      },
      {
        id: generateRandomID(),
        title: "Technical Drawing",
        classLevel: "SSS1",
      },
      {
        id: generateRandomID(),
        title: "Physics",
        classLevel: "SSS1",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Sarah",
    middlename: "Jane",
    lastname: "Miller",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1988-07-20",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Physics",
        classLevel: "SSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Titi",
    middlename: "Sola",
    lastname: "Toba",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1988-07-20",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Chemistry",
        classLevel: "SSS3",
      },
      {
        id: generateRandomID(),
        title: "Chemistry",
        classLevel: "SSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Adu",
    middlename: "Todu",
    lastname: "Udo",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1988-07-20",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Ade",
    middlename: "Sode",
    lastname: "Osha",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1988-07-20",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Niran",
    middlename: "Posp",
    lastname: "Mahs",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1988-07-20",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Bishop",
    middlename: "Bish",
    lastname: "Maus",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1988-07-20",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Precs",
    middlename: "Oghi",
    lastname: "Nopsy",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1988-07-20",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Bops",
    middlename: "Min",
    lastname: "Dosp",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1988-07-20",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Michael",
    middlename: "Lee",
    lastname: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1983-09-07",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Emily",
    middlename: "Grace",
    lastname: "Wilson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1986-03-12",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Daniel",
    middlename: "Thomas",
    lastname: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1989-11-02",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Olivia",
    middlename: "Nicole",
    lastname: "Martinez",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1991-06-28",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Christopher",
    middlename: "William",
    lastname: "Taylor",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1982-04-19",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Sophia",
    middlename: "Grace",
    lastname: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1984-10-23",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Matthew",
    middlename: "James",
    lastname: "Jackson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1993-01-14",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Ava",
    middlename: "Rose",
    lastname: "Harris",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1987-12-05",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Joseph",
    middlename: "Alexander",
    lastname: "White",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1981-08-17",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Chloe",
    middlename: "Elizabeth",
    lastname: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1989-06-30",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Liam",
    middlename: "Christopher",
    lastname: "Thomas",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1985-09-14",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Emma",
    middlename: "Rose",
    lastname: "Allen",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1990-02-22",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Noah",
    middlename: "Benjamin",
    lastname: "Scott",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1987-07-06",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Isabella",
    middlename: "Grace",
    lastname: "Roberts",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1992-04-11",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Oliver",
    middlename: "Daniel",
    lastname: "Turner",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1984-11-30",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Amelia",
    middlename: "Sophia",
    lastname: "Parker",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1988-12-19",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Elijah",
    middlename: "Jacob",
    lastname: "Adams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1993-08-08",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Mia",
    middlename: "Grace",
    lastname: "Stewart",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1989-05-17",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Aiden",
    middlename: "Michael",
    lastname: "Cooper",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1982-06-09",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Charlotte",
    middlename: "Olivia",
    lastname: "Phillips",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1986-03-28",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "James",
    middlename: "Andrew",
    lastname: "Campbell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1981-07-12",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Ethan",
    middlename: "Alexander",
    lastname: "Mitchell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1983-10-20",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Harper",
    middlename: "Grace",
    lastname: "Carter",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1985-09-26",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Benjamin",
    middlename: "Michael",
    lastname: "Baker",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1988-06-03",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Scarlett",
    middlename: "Rose",
    lastname: "Ward",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1991-02-18",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Logan",
    middlename: "Daniel",
    lastname: "Griffin",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1982-11-28",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Aria",
    middlename: "Elizabeth",
    lastname: "Foster",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1984-07-06",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Lucas",
    middlename: "William",
    lastname: "Reed",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1993-05-14",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Grace",
    middlename: "Isabella",
    lastname: "Patterson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1987-03-22",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Mason",
    middlename: "James",
    lastname: "Morgan",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1989-08-19",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Lily",
    middlename: "Sophia",
    lastname: "Hughes",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1986-04-27",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Elijah",
    middlename: "Robert",
    lastname: "Harrison",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1981-09-15",
    gender: "male",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
      {
        id: generateRandomID(),
        title: "Science",
        classLevel: "JSS2",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Sofia",
    middlename: "Emily",
    lastname: "Russell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1988-12-09",
    gender: "female",
    subjects: [
      {
        id: generateRandomID(),
        title: "Mathematics",
        classLevel: "JSS1",
      },
    ],
  },
  {
    id: generateRandomID(),
    firstname: "Aiden",
    middlename: "Matthew",
    lastname: "Bennett",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    dateOfBirth: "1983-02-12",
    gender: "male",
    subjects: [],
  },
];

export default teachersData;
