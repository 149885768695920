import React, { useState, useEffect } from "react";

function AdminPopup({ admin, onClose }) {
  const [adminName, setAdminName] = useState("");
  const [adminPosition, setAdminPosition] = useState("");
  const [adminGender, setAdminGender] = useState("");
  const [adminImage, setAdminImage] = useState(
    admin
      ? admin.image
      : "https://static-00.iconduck.com/assets.00/avatar-default-symbolic-icon-2048x1949-pq9uiebg.png"
  );
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (admin) {
      setAdminName(admin.name);
      setAdminPosition(admin.position);
      setAdminGender(admin.gender);
      setAdminImage(admin.image);
    }
  }, [admin]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAdminImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = () => {
    // Either create or update the admin, for now, you can update the dummy list.
    // Later on, make a POST or PUT request to your API.
    onClose();
  };

  return (
    <div className="admin-popup-container">
      <h3>{admin ? "Edit Admin" : "Add Admin"}</h3>
      <div className="image-upload">
        <label htmlFor="admin-image">
          <img
            src={adminImage}
            alt="Admin Thumbnail"
            className="admin-thumbnail"
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
          />
          {hover && <i className="fas fa-camera camera-icon"></i>}
        </label>
        <input
          id="admin-image"
          type="file"
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />
      </div>
      <input
        type="text"
        placeholder="Name"
        value={adminName}
        onChange={(e) => setAdminName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Position"
        value={adminPosition}
        onChange={(e) => setAdminPosition(e.target.value)}
      />
      <select
        value={adminGender}
        onChange={(e) => setAdminGender(e.target.value)}
      >
        <option value="">Select Gender</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
      </select>
      <div className="buttons-wrapper">
        <button onClick={handleSubmit}>Submit</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
}

export default AdminPopup;
