import React from "react";

function ClassLevelSelect({ classLevel, handleChange }) {
  return (
    <div className="class-level-select">
      <select
        id="classLevel"
        name="classLevel"
        value={classLevel}
        onChange={(event) => handleChange(event.target.value)}
        required
      >
        <option value="">Select Class Level</option>
        <option value="JSS1">JSS1</option>
        <option value="JSS2">JSS2</option>
        <option value="JSS3">JSS3</option>
        <option value="SSS1">SSS1</option>
        <option value="SSS2">SSS2</option>
        <option value="SSS3">SSS3</option>
      </select>
    </div>
  );
}

export default ClassLevelSelect;
