import { React, useEffect, useRef } from "react";
import Chart from "chart.js/auto";

function StudentNumberChart({ classData }) {
  const chartInstanceRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    const totalStudents = classData.reduce(
      (sum, data) => sum + data.totalStudents,
      0
    );

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const chartCanvas = chartRef.current.getContext("2d");
    const newChartInstance = new Chart(chartCanvas, {
      type: "bar",
      data: {
        labels: classData.map((data) => data.class),
        datasets: [
          {
            label: "Total Students",
            data: classData.map((data) => data.totalStudents),
            backgroundColor: "rgba(112, 144, 184, 0.66)",
            borderColor: "rgba(4, 40, 85, 1)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            stepSize: 10,
          },
        },
        plugins: {
          title: {
            display: true,
            text: "Total Number of Students: " + totalStudents,
          },
        },
      },
    });

    chartInstanceRef.current = newChartInstance;
  }, []);

  return (
    <div className="chart-container">
      <canvas ref={chartRef}></canvas>
      <div className="chart-title">Student Number by Class</div>
    </div>
  );
}

export default StudentNumberChart;
