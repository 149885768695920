import React from "react";

function AddressInputs({ address, handleAddressChange }) {
  return (
    <div className="address-inputs">
      <textarea
        id="fullAddress"
        name="address"
        value={address}
        onChange={handleAddressChange}
        required
      />
    </div>
  );
}

export default AddressInputs;
