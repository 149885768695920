import React from "react";

function Topbar({
  segmentTitle,
  segmentIcon,
  handleHomeClick,
  handleNotificationsClick,
  handleSettingsClick,
}) {
  return (
    <div className="top-bar">
      <div className="segment-title">
        <i className={segmentIcon}></i>
        <span className="marking"></span>
        {segmentTitle}
      </div>
      <div className="top-icons">
        <i className="fas fa-home" onClick={handleHomeClick}></i>
        <i className="fas fa-bell" onClick={handleNotificationsClick}></i>
        <i className="fas fa-cog" onClick={handleSettingsClick}></i>
        <i className="fas fa-sign-out-alt"></i>
      </div>
    </div>
  );
}

export default Topbar;
