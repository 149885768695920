import React from "react";
import AdminItem from "./AdminItem";
import adminData from "../../../data/DummyAdminData"

function AdminList({ onEdit }) {
    return (
        <div className="admin-list-container">
            {adminData.map(admin => (
                <AdminItem key={admin.id} admin={admin} onEdit={onEdit} />
            ))}
        </div>
    );
}

export default AdminList;
