class Segment {
  constructor(title, segmentScreen, id, icon) {
    this.title = title;
    this.segmentScreen = segmentScreen;
    this.id = id;
    this.icon = icon;
  }
}

export default Segment;
