import React from "react";
import "./print.css";

const PrintablePage = ({ filteredStudents, filters, onClose }) => {
  const handlePrint = () => {
    const printablePage = document.querySelector(".printable-page");
    const printWindow = window.open("", "_blank");
    printWindow.document.write(printablePage.outerHTML);
    // const printablePageClone = printablePage.cloneNode(true);
    // printWindow.document.body.appendChild(printablePageClone);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className="printable-page-overlay">
      <div className="printable-page">
        <div className="print-close" onClick={onClose}>
          <i className="fas fa-times"></i>
        </div>
        <div className="sub-heading">
          {" "}
          {`${filters.classLevel || "All"} ${filters.classArm || ""} ${
            filters.gender || ""
          } students`}
        </div>
        <table className="students-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Student Name</th>
              <th>Student ID</th>
              <th> </th>
              <th> </th>
              <th> </th>
              <th> </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {filteredStudents.map((student, index) => (
              <tr key={student.id}>
                <td>{index + 1}</td>
                <td>{`${student.lastName} ${student.firstName} ${student.middleName}`}</td>
                <td>{student.id}</td>
                {[...Array(5)].map((_, cbIndex) => (
                  <td key={cbIndex} className="cb-cell"></td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <button className="print-button1" onClick={handlePrint}>
          Print
        </button>
      </div>
    </div>
  );
};

export default PrintablePage;
