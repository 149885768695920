import React from "react";

function AvatarUpload({ handleAvatarChange, imageURL, setImageURL}) {

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const imageDataURL = event.target.result;
      setImageURL(imageDataURL);
      handleAvatarChange(imageDataURL);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="avatar-upload">
      <div className="avatar-container">
        <label htmlFor="avatar-input" className="avatar-label">
          <img
            src={
              imageURL ||
              "https://static-00.iconduck.com/assets.00/avatar-default-symbolic-icon-2048x1949-pq9uiebg.png"
            }
            alt="Avatar"
            className="avatar-image"
          />
          <div className="avatar-overlay">
            <i className="fas fa-camera"></i>
          </div>
        </label>
        <input
          type="file"
          id="avatar-input"
          accept="image/*"
          onChange={handleImageUpload}
          className="avatar-input"
        />
      </div>
    </div>
  );
}

export default AvatarUpload;
