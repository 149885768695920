import React from "react";
import { Pie } from "react-chartjs-2";

function GenderRatioChart({ studentData }) {
  const genderRatioByClassLevel = studentData.reduce((accumulator, student) => {
    if (!accumulator[student.classLevel]) {
      accumulator[student.classLevel] = {
        male: 0,
        female: 0,
      };
    }
    if (student.gender === "Male") {
      accumulator[student.classLevel].male++;
    } else if (student.gender === "Female") {
      accumulator[student.classLevel].female++;
    }
    return accumulator;
  }, {});

  const classLevels = Object.keys(genderRatioByClassLevel);

  const genderRatioChartData = {
    labels: classLevels,
    datasets: [
      {
        label: "Male",
        data: classLevels.map(
          (classLevel) => genderRatioByClassLevel[classLevel].male
        ),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
      {
        label: "Female",
        data: classLevels.map(
          (classLevel) => genderRatioByClassLevel[classLevel].female
        ),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  return (
    <div className="chart">
      <h3 className="chart-title">Male-Female Ratio by Class Levels</h3>
      <div className="chart-container-s">
        <Pie
          data={genderRatioChartData}
          options={{ maintainAspectRatio: false }}
        />
      </div>
    </div>
  );
}

export default GenderRatioChart;
