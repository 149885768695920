import React from "react";

function GenderSelect({ gender, handleChange }) {
  return (
    <div className="gender-select">
      <select
        id="gender"
        name="gender"
        value={gender}
        onChange={(event) => handleChange(event.target.value)}
        required
      >
        <option value="">Select Gender</option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
      </select>
    </div>
  );
}

export default GenderSelect;
