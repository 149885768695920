const studentData = [];

// Generate random ID
function generateRandomID() {
  return Math.random().toString(36).substr(2, 9);
}

// JSS1 Students
const jss1Students = [
  // Arm A
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Jane",
    middleName: "Doe",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "A",
    age: 10,
    gender: "Female",
  },
  // Arm B
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Brown",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 12,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Julia",
    middleName: "Taylor",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "B",
    age: 11,
    gender: "Male",
  },
  // Arm C
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Wilson",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 11,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Thomas",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS1",
    classArm: "C",
    age: 10,
    gender: "Female",
  },
  // Add more JSS1 students...
  // ...
];

// Add JSS1 students to the main student data array
studentData.push(...jss1Students);

// JSS2 Students
const jss2Students = [
  // Arm A
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  // Arm B
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Clark",
    lastName: "Williams",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Olivia",
    middleName: "Brown",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 13,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 13,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "B",
    age: 14,
    gender: "Female",
  },
  // Arm C
  {
    id: generateRandomID(),
    firstName: "Matthew",
    middleName: "Davis",
    lastName: "Wilson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "C",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ava",
    middleName: "Thomas",
    lastName: "Taylor",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "C",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emily",
    middleName: "Taylor",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Smith",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS2",
    classArm: "A",
    age: 13,
    gender: "Female",
  },
  // Add more JSS2 students...
  // ...
];

// Add JSS2 students to the main student data array
studentData.push(...jss2Students);

// JSS3 Students
const jss3Students = [
  // Arm A
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Miller",
    lastName: "Wilson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Anderson",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Miller",
    lastName: "Wilson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Anderson",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Miller",
    lastName: "Wilson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Anderson",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Miller",
    lastName: "Wilson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Anderson",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Miller",
    lastName: "Wilson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Anderson",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Miller",
    lastName: "Wilson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Anderson",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Miller",
    lastName: "Wilson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Anderson",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Miller",
    lastName: "Wilson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Anderson",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Miller",
    lastName: "Wilson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Anderson",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Miller",
    lastName: "Wilson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Anderson",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Miller",
    lastName: "Wilson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Anderson",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Miller",
    lastName: "Wilson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Anderson",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "A",
    age: 15,
    gender: "Female",
  },
  // Arm B
  {
    id: generateRandomID(),
    firstName: "Joseph",
    middleName: "Harris",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Clark",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Joseph",
    middleName: "Harris",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Clark",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Joseph",
    middleName: "Harris",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Clark",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Joseph",
    middleName: "Harris",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Clark",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Joseph",
    middleName: "Harris",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Clark",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Joseph",
    middleName: "Harris",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Clark",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Joseph",
    middleName: "Harris",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Clark",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Joseph",
    middleName: "Harris",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Clark",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Joseph",
    middleName: "Harris",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Clark",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Joseph",
    middleName: "Harris",
    lastName: "Brown",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Clark",
    lastName: "Davis",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "B",
    age: 15,
    gender: "Female",
  },
  // Arm C
  {
    id: generateRandomID(),
    firstName: "Samuel",
    middleName: "Wilson",
    lastName: "Jones",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Taylor",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Samuel",
    middleName: "Wilson",
    lastName: "Jones",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Taylor",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Samuel",
    middleName: "Wilson",
    lastName: "Jones",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Taylor",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Samuel",
    middleName: "Wilson",
    lastName: "Jones",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Taylor",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Samuel",
    middleName: "Wilson",
    lastName: "Jones",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Taylor",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Samuel",
    middleName: "Wilson",
    lastName: "Jones",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 14,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Taylor",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Samuel",
    middleName: "Wilson",
    lastName: "Jones",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Taylor",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Samuel",
    middleName: "Wilson",
    lastName: "Jones",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 14,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Taylor",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 15,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Samuel",
    middleName: "Wilson",
    lastName: "Jones",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Taylor",
    lastName: "Johnson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "JSS3",
    classArm: "C",
    age: 15,
    gender: "Female",
  },
  // Add more JSS3 students...
  // ...
];

// Add JSS3 students to the main student data array
studentData.push(...jss3Students);

// SSS1 Students
const sss1Students = [
  // Arm A
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Johnson",
    lastName: "Miller",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Olivia",
    middleName: "Wilson",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Johnson",
    lastName: "Miller",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Olivia",
    middleName: "Wilson",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Johnson",
    lastName: "Miller",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Olivia",
    middleName: "Wilson",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Johnson",
    lastName: "Miller",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Olivia",
    middleName: "Wilson",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Johnson",
    lastName: "Miller",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Olivia",
    middleName: "Wilson",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Johnson",
    lastName: "Miller",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Olivia",
    middleName: "Wilson",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Johnson",
    lastName: "Miller",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Olivia",
    middleName: "Wilson",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Johnson",
    lastName: "Miller",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Olivia",
    middleName: "Wilson",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Johnson",
    lastName: "Miller",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Olivia",
    middleName: "Wilson",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Johnson",
    lastName: "Miller",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Olivia",
    middleName: "Wilson",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Johnson",
    lastName: "Miller",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Olivia",
    middleName: "Wilson",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Johnson",
    lastName: "Miller",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Olivia",
    middleName: "Wilson",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Johnson",
    lastName: "Miller",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Olivia",
    middleName: "Wilson",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "A",
    age: 16,
    gender: "Male",
  },
  // Arm B
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Brown",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emma",
    middleName: "Davis",
    lastName: "Harris",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Brown",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emma",
    middleName: "Davis",
    lastName: "Harris",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Brown",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emma",
    middleName: "Davis",
    lastName: "Harris",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Brown",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emma",
    middleName: "Davis",
    lastName: "Harris",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Brown",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emma",
    middleName: "Davis",
    lastName: "Harris",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Brown",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emma",
    middleName: "Davis",
    lastName: "Harris",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Brown",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emma",
    middleName: "Davis",
    lastName: "Harris",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Brown",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emma",
    middleName: "Davis",
    lastName: "Harris",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Brown",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emma",
    middleName: "Davis",
    lastName: "Harris",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "David",
    middleName: "Brown",
    lastName: "Smith",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Emma",
    middleName: "Davis",
    lastName: "Harris",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "B",
    age: 16,
    gender: "Female",
  },
  // Arm C
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Jones",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Johnson",
    lastName: "Taylor",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Jones",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Johnson",
    lastName: "Taylor",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Jones",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Johnson",
    lastName: "Taylor",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Jones",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Johnson",
    lastName: "Taylor",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Jones",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Johnson",
    lastName: "Taylor",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Jones",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Johnson",
    lastName: "Taylor",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Jones",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Johnson",
    lastName: "Taylor",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Jones",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Johnson",
    lastName: "Taylor",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Jones",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Johnson",
    lastName: "Taylor",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Jones",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Johnson",
    lastName: "Taylor",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Jones",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Johnson",
    lastName: "Taylor",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 16,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Jones",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Johnson",
    lastName: "Taylor",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Michael",
    middleName: "Jones",
    lastName: "Clark",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 15,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Johnson",
    lastName: "Taylor",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS1",
    classArm: "C",
    age: 16,
    gender: "Female",
  },
  // Add more SSS1 students...
  // ...
];

// Add SSS1 students to the main student data array
studentData.push(...sss1Students);

// SSS2 Students
const sss2Students = [
  // Arm A
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Wilson",
    lastName: "Thompson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ava",
    middleName: "Harris",
    lastName: "Turner",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Wilson",
    lastName: "Thompson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ava",
    middleName: "Harris",
    lastName: "Turner",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Wilson",
    lastName: "Thompson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ava",
    middleName: "Harris",
    lastName: "Turner",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Wilson",
    lastName: "Thompson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ava",
    middleName: "Harris",
    lastName: "Turner",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Wilson",
    lastName: "Thompson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ava",
    middleName: "Harris",
    lastName: "Turner",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Wilson",
    lastName: "Thompson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ava",
    middleName: "Harris",
    lastName: "Turner",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Wilson",
    lastName: "Thompson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ava",
    middleName: "Harris",
    lastName: "Turner",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Wilson",
    lastName: "Thompson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ava",
    middleName: "Harris",
    lastName: "Turner",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Wilson",
    lastName: "Thompson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ava",
    middleName: "Harris",
    lastName: "Turner",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Wilson",
    lastName: "Thompson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ava",
    middleName: "Harris",
    lastName: "Turner",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "James",
    middleName: "Wilson",
    lastName: "Thompson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ava",
    middleName: "Harris",
    lastName: "Turner",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "A",
    age: 17,
    gender: "Male",
  },
  // Arm B
  {
    id: generateRandomID(),
    firstName: "William",
    middleName: "Roberts",
    lastName: "Mitchell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Baker",
    lastName: "Carter",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "William",
    middleName: "Roberts",
    lastName: "Mitchell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Baker",
    lastName: "Carter",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "William",
    middleName: "Roberts",
    lastName: "Mitchell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Baker",
    lastName: "Carter",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "William",
    middleName: "Roberts",
    lastName: "Mitchell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Baker",
    lastName: "Carter",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "William",
    middleName: "Roberts",
    lastName: "Mitchell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Baker",
    lastName: "Carter",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "William",
    middleName: "Roberts",
    lastName: "Mitchell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Baker",
    lastName: "Carter",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "William",
    middleName: "Roberts",
    lastName: "Mitchell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Baker",
    lastName: "Carter",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "William",
    middleName: "Roberts",
    lastName: "Mitchell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Baker",
    lastName: "Carter",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "William",
    middleName: "Roberts",
    lastName: "Mitchell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Baker",
    lastName: "Carter",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "William",
    middleName: "Roberts",
    lastName: "Mitchell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Baker",
    lastName: "Carter",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "William",
    middleName: "Roberts",
    lastName: "Mitchell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Isabella",
    middleName: "Baker",
    lastName: "Carter",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "B",
    age: 17,
    gender: "Female",
  },

  // Arm C
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Lee",
    lastName: "Ward",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Turner",
    lastName: "Stewart",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Lee",
    lastName: "Ward",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Turner",
    lastName: "Stewart",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Lee",
    lastName: "Ward",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Turner",
    lastName: "Stewart",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Lee",
    lastName: "Ward",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Turner",
    lastName: "Stewart",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Lee",
    lastName: "Ward",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Turner",
    lastName: "Stewart",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Lee",
    lastName: "Ward",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Turner",
    lastName: "Stewart",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Lee",
    lastName: "Ward",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Turner",
    lastName: "Stewart",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Lee",
    lastName: "Ward",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Turner",
    lastName: "Stewart",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Lee",
    lastName: "Ward",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Turner",
    lastName: "Stewart",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Lee",
    lastName: "Ward",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Turner",
    lastName: "Stewart",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Lee",
    lastName: "Ward",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Turner",
    lastName: "Stewart",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 17,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "John",
    middleName: "Lee",
    lastName: "Ward",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 16,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Mia",
    middleName: "Turner",
    lastName: "Stewart",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS2",
    classArm: "C",
    age: 17,
    gender: "Female",
  },
  // Add more SSS2 students...
  // ...
];

// Add SSS2 students to the main student data array
studentData.push(...sss2Students);

// SSS3 Students
const sss3Students = [
  // Arm A
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Hughes",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ella",
    middleName: "Foster",
    lastName: "Price",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 18,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Hughes",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ella",
    middleName: "Foster",
    lastName: "Price",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Hughes",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ella",
    middleName: "Foster",
    lastName: "Price",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Hughes",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ella",
    middleName: "Foster",
    lastName: "Price",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Hughes",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ella",
    middleName: "Foster",
    lastName: "Price",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Hughes",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ella",
    middleName: "Foster",
    lastName: "Price",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Hughes",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ella",
    middleName: "Foster",
    lastName: "Price",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 18,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Hughes",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ella",
    middleName: "Foster",
    lastName: "Price",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Hughes",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ella",
    middleName: "Foster",
    lastName: "Price",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Hughes",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ella",
    middleName: "Foster",
    lastName: "Price",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Daniel",
    middleName: "Hughes",
    lastName: "Anderson",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Ella",
    middleName: "Foster",
    lastName: "Price",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "A",
    age: 18,
    gender: "Female",
  },
  // Arm B
  {
    id: generateRandomID(),
    firstName: "Matthew",
    middleName: "Wright",
    lastName: "Reed",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Butler",
    lastName: "Bailey",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Matthew",
    middleName: "Wright",
    lastName: "Reed",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Butler",
    lastName: "Bailey",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Matthew",
    middleName: "Wright",
    lastName: "Reed",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Butler",
    lastName: "Bailey",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Matthew",
    middleName: "Wright",
    lastName: "Reed",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Butler",
    lastName: "Bailey",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Matthew",
    middleName: "Wright",
    lastName: "Reed",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Butler",
    lastName: "Bailey",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Matthew",
    middleName: "Wright",
    lastName: "Reed",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Butler",
    lastName: "Bailey",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Matthew",
    middleName: "Wright",
    lastName: "Reed",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Butler",
    lastName: "Bailey",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Matthew",
    middleName: "Wright",
    lastName: "Reed",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Butler",
    lastName: "Bailey",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Matthew",
    middleName: "Wright",
    lastName: "Reed",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Butler",
    lastName: "Bailey",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Matthew",
    middleName: "Wright",
    lastName: "Reed",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Sophia",
    middleName: "Butler",
    lastName: "Bailey",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Emmanuel",
    middleName: "Mayowa",
    lastName: "Adekunle",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Christianah",
    middleName: "Ayomide",
    lastName: "Adekunle",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "B",
    age: 18,
    gender: "Female",
  },
  // Arm C
  {
    id: generateRandomID(),
    firstName: "Oliver",
    middleName: "Hughes",
    lastName: "Bell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "C",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Charlotte",
    middleName: "Stewart",
    lastName: "Ramirez",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "C",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Oliver",
    middleName: "Hughes",
    lastName: "Bell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "C",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Charlotte",
    middleName: "Stewart",
    lastName: "Ramirez",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "C",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Oliver",
    middleName: "Hughes",
    lastName: "Bell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "C",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Charlotte",
    middleName: "Stewart",
    lastName: "Ramirez",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "C",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Oliver",
    middleName: "Hughes",
    lastName: "Bell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "C",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Charlotte",
    middleName: "Stewart",
    lastName: "Ramirez",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "C",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Oliver",
    middleName: "Hughes",
    lastName: "Bell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "C",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Charlotte",
    middleName: "Stewart",
    lastName: "Ramirez",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "C",
    age: 18,
    gender: "Female",
  },
  {
    id: generateRandomID(),
    firstName: "Oliver",
    middleName: "Hughes",
    lastName: "Bell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "C",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Charlotte",
    middleName: "Stewart",
    lastName: "Ramirez",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "C",
    age: 18,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Oliver",
    middleName: "Hughes",
    lastName: "Bell",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "C",
    age: 17,
    gender: "Male",
  },
  {
    id: generateRandomID(),
    firstName: "Charlotte",
    middleName: "Stewart",
    lastName: "Ramirez",
    displayPicture:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    classLevel: "SSS3",
    classArm: "C",
    age: 18,
    gender: "Female",
  },
  // Add more SSS3 students...
  // ...
];

// Add SSS3 students to the main student data array
studentData.push(...sss3Students);

export default studentData;
