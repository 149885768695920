import React from "react";
import segmentsList from "../models/SegmentsList";

function Sidebar({ handleSegmentClick, selectedSegment }) {
  const segments = segmentsList;

  const profileUrl =
    "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50";

  return (
    <div className="sidebar-main">
      <div className="sidebar-top">
        {/* Topbar content */}
        Student Management Admin
      </div>
      <div className="sidebar-content">
        <div style={{ padding: "20px" }}>
          {/* Profile section */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // marginTop: "20%",
            }}
          >
            <img
              src={profileUrl}
              alt="Profile"
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                marginBottom: "10px",
              }}
            />
            <span
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Emmanuel Mayowa Adekunle
            </span>
            <span style={{ fontSize: "12px", marginBottom: "10px" }}>
              ad28990jkkmq9ujn
            </span>
          </div>

          {/* Buttons section */}
          <div>
            <ul
              style={{ listStyle: "none", padding: "0" }}
              className="nav flex-column"
            >
              {segments.map((segment, index) => (
                <li key={index} className="nav-item">
                  <div
                    className={`nav-link ${
                      selectedSegment && selectedSegment.id === segment.id
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => handleSegmentClick(segment)}
                  >
                    <i className={segment.icon}></i> {segment.title}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
