import React, { useState } from "react";
import AdminList from "./admin_segment_screen/AdminList";
import AdminPopup from "./admin_segment_screen/AdminPopup";

function AdminSegmentScreen() {
    const [showPopup, setShowPopup] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState(null);

    const handleAddAdmin = () => {
        setSelectedAdmin(null);
        setShowPopup(true);
    };

    const handleEditAdmin = (admin) => {
        setSelectedAdmin(admin);
        setShowPopup(true);
    };

    return (
        <div className="admin-segment-container">
            <h2>Admin Management</h2>
            <i className="fas fa-user-plus add-admin-icon" onClick={handleAddAdmin}></i>
            <AdminList onEdit={handleEditAdmin} />
            {showPopup && <AdminPopup admin={selectedAdmin} onClose={() => setShowPopup(false)} />}
        </div>
    );
}

export default AdminSegmentScreen;
