import React from "react";

function GuardianInfo({ guardian, handleGuardianChange }) {
  return (
    <div className="guardian-info">
      <div className="guardian-inputs">
        <label htmlFor="guardianName">Guardian Name *</label>
        <input
          type="text"
          id="guardianName"
          name="guardianName"
          value={guardian.guardianName}
          onChange={handleGuardianChange}
          required
        />

        <label htmlFor="guardianAddress">Guardian Address *</label>
        <textarea
          id="guardianAddress"
          name="guardianAddress"
          value={guardian.guardianAddress}
          onChange={handleGuardianChange}
          required
        />

        <label htmlFor="guardianPhoneNumber">Guardian Phone Number</label>
        <input
          type="text"
          id="guardianPhoneNumber"
          name="guardianPhoneNumber"
          value={guardian.guardianPhoneNumber}
          onChange={handleGuardianChange}
        />

        <label htmlFor="guardianEmail">Guardian Email</label>
        <input
          type="text"
          id="guardianEmail"
          name="guardianEmail"
          value={guardian.guardianEmail}
          onChange={handleGuardianChange}
        />

        <label htmlFor="guardianGender">Guardian Gender</label>
        <select
          id="guardianGender"
          name="guardianGender"
          value={guardian.guardianGender}
          onChange={handleGuardianChange}
        >
          <option value="">Select</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>

        <label htmlFor="guardianRelationship">Guardian Relationship</label>
        <select
          id="guardianRelationship"
          name="guardianRelationship"
          value={guardian.guardianRelationship}
          onChange={handleGuardianChange}
        >
          <option value="">Select</option>
          <option value="Father">Father</option>
          <option value="Mother">Mother</option>
          <option value="Brother">Brother</option>
          <option value="Sister">Sister</option>
          <option value="Uncle">Uncle</option>
          <option value="Aunt">Aunt</option>
          <option value="Others">Others</option>
        </select>

        <label htmlFor="guardianOccupation">Guardian Occupation</label>
        <input
          type="text"
          id="guardianOccupation"
          name="guardianOccupation"
          value={guardian.guardianOccupation}
          onChange={handleGuardianChange}
        />
      </div>
    </div>
  );
}

export default GuardianInfo;
