import React from "react";

function DeleteTeacherConfirmationPopup({ onCancel, onDelete }) {
  return (
    <div className="delete-confirmation-popup">
      <p>Are you sure you want to delete this Teacher?</p>
      <div className="delete-confirmation-buttons">
        <button onClick={onCancel}>Cancel</button>
        <button onClick={onDelete}>Delete</button>
      </div>
    </div>
  );
}

export default DeleteTeacherConfirmationPopup;
