import React from "react";

function ContentCard({ icon, text, subText, handleCardClick }) {
  return (
    <div className="content-card" onClick={handleCardClick}>
      <div className="card-icon">{icon}</div>
      <div className="card-text">
        {text}
        <span className="card-sub-text">{subText}</span>
      </div>
    </div>
  );
}

export default ContentCard;
