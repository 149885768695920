import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
} from "react-router-dom";
import MainScreen from "./screens/MainScreen";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<MainScreen />} />
    </Routes>
  );
}

export default App;
