import React, { useState } from "react";
import AvatarUpload from "../student_segment_screen/screen_components/AvatarUpload";
import NameInputs from "../student_segment_screen/screen_components/NameInputs";
import DatePicker from "../student_segment_screen/screen_components/DatePicker";
import GenderSelect from "../student_segment_screen/screen_components/GenderSelect";
import ClassLevelSelect from "../student_segment_screen/screen_components/ClassLevelSelect";
import AddressInputs from "../student_segment_screen/screen_components/AddressInputs";
import GuardianInfo from "../student_segment_screen/screen_components/GuardianInfo";
import SaveButton from "../student_segment_screen/screen_components/SaveButton";

function CreateNewStudentComponent({ handleBackButtonClick }) {
  const [imageURL, setImageURL] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [gender, setGender] = useState("");
  const [classLevel, setClassLevel] = useState("");
  const [address, setAddress] = useState("");
  const [guardian, setGuardian] = useState({
    guardianName: "",
    guardianAddress: "",
    guardianPhoneNumber: "",
    guardianEmail: "",
    guardianGender: "",
    guardianRelationship: "",
    guardianOccupation: "",
  });

  const handleAvatarChange = (selectedAvatar) => {
    setAvatar(selectedAvatar);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "firstName":
        setFirstName(value);
        break;
      case "middleName":
        setMiddleName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      default:
        break;
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleGenderChange = (selectedGender) => {
    setGender(selectedGender);
  };

  const handleClassLevelChange = (selectedClassLevel) => {
    setClassLevel(selectedClassLevel);
  };

  const handleAddressChange = (event) => {
    const { value } = event.target;
    setAddress(value);
  };

  const handleGuardianChange = (event) => {
    const { name, value } = event.target;
    setGuardian((prevGuardian) => ({
      ...prevGuardian,
      [name]: value,
    }));
  };

  const handleSaveButtonClick = () => {
    const inputData = {
      avatar,
      firstName,
      lastName,
      middleName,
      selectedDate,
      gender,
      classLevel,
      address,
      guardian,
    };

    console.log(inputData);
  };

  return (
    <div className="component-screen">
      <div className="header-comp">
        <div onClick={handleBackButtonClick} className="back-btn-sg">
          <i className="fas fa-arrow-left"></i> Back
        </div>
        <div className="header-txt">
          <h3>Create New Student</h3>
        </div>
      </div>
      <div className="component-content">
        <h4>Student Display Picture</h4>
        <AvatarUpload
          handleAvatarChange={handleAvatarChange}
          setImageURL={setImageURL}
          imageURL={imageURL}
        />

        <h4>Student Name</h4>
        <NameInputs
          firstName={firstName}
          lastName={lastName}
          middleName={middleName}
          onChange={handleChange}
        />

        <h4>Date of Birth</h4>
        <DatePicker
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
        />

        <h4>Gender</h4>
        <GenderSelect gender={gender} handleChange={handleGenderChange} />

        <h4>Class Level</h4>
        <ClassLevelSelect
          classLevel={classLevel}
          handleChange={handleClassLevelChange}
        />

        <h4>Address</h4>
        <AddressInputs
          address={address}
          handleAddressChange={handleAddressChange}
        />

        <h4>Guardian Information</h4>
        <GuardianInfo
          guardian={guardian}
          handleGuardianChange={handleGuardianChange}
        />

        <SaveButton handleClick={handleSaveButtonClick} />
      </div>
    </div>
  );
}

export default CreateNewStudentComponent;
