import React, { useState } from "react";
import CreateNewTeacher from "./teacher_segment_screen/CreateNewTeacher";
import EditTeacher from "./teacher_segment_screen/EditTeacher";
import TeacherCard from "./teacher_segment_screen/screen_components/TeacherCard";
import SubjectListPopup from "./teacher_segment_screen/screen_components/SubjectListPopup";
import DeleteTeacherConfirmationPopup from "./teacher_segment_screen/screen_components/DeleteTeacherConfirmationPopup";
import teachersData from "../../data/DummyTeacherData";

function TeacherSegmentScreen() {
  const [isCreateTeacherOpen, setCreateTeacherOpen] = useState(false);
  const [isEditTeacherOpen, setEditTeacherOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedTeacherSubjects, setSelectedTeacherSubjects] = useState([]);
  const [isSubjectListPopupOpen, setSubjectListPopupOpen] = useState(false);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [teacherToDelete, setTeacherToDelete] = useState(null);

  const handleCreateTeacherClick = () => {
    setCreateTeacherOpen(true);
  };

  const handleCloseCreateTeacher = () => {
    setCreateTeacherOpen(false);
  };

  const handleEditTeacherClick = (teacher) => {
    setSelectedTeacher(teacher);
    setEditTeacherOpen(true);
  };

  const handleCloseEditTeacher = () => {
    setSelectedTeacher(null);
    setEditTeacherOpen(false);
  };

  const handleSaveEditTeacher = (formData) => {
    // Add logic to update the teacher's details in your data or API
    console.log("Updated Teacher Data:", formData);
  };

  const handleViewSubjectsClick = (teacher) => {
    setSelectedTeacher(teacher);
    setSelectedTeacherSubjects(teacher.subjects);
    setSubjectListPopupOpen(true);
  };

  const handleCloseSubjectListPopup = () => {
    setSelectedTeacher(null);
    setSelectedTeacherSubjects([]);
    setSubjectListPopupOpen(false);
  };

  const handleDeleteClick = (teacher) => {
    console.log("here");
    setTeacherToDelete(teacher);
    setDeletePopupOpen(true);
    console.log(isDeletePopupOpen);
  };

  const handleDeleteTeacher = () => {
    // Add logic to delete the teacher from your data or API
    console.log("Deleted Teacher:", teacherToDelete);
    // Close the confirmation popup and reset the teacherToDelete state
    setDeletePopupOpen(false);
    setTeacherToDelete(null);
  };

  return (
    <div>
      <div className="teacher-main">
        <div className="teacher-top-bar">
          <h1 className="teacher-heading">Teachers</h1>
          <div
            className="teacher-top-icons"
            title="Create new user"
            onClick={handleCreateTeacherClick}
          >
            <i className="fas fa-user-plus"></i>
          </div>
        </div>
        <div className="teacher-container">
          <div className="teacher-grid-container">
            {teachersData.map((teacher) => (
              <TeacherCard
                key={teacher.id}
                teacher={teacher}
                onEdit={() => handleEditTeacherClick(teacher)}
                onViewSubjects={() => handleViewSubjectsClick(teacher)}
                onDelete={() => handleDeleteClick(teacher)}
              />
            ))}
          </div>
        </div>
      </div>
      {isCreateTeacherOpen && (
        <CreateNewTeacher onClose={handleCloseCreateTeacher} />
      )}
      {isEditTeacherOpen && selectedTeacher && (
        <EditTeacher
          teacher={selectedTeacher}
          onClose={handleCloseEditTeacher}
          onSave={handleSaveEditTeacher}
        />
      )}
      {isSubjectListPopupOpen && selectedTeacher && (
        <SubjectListPopup
          subjects={selectedTeacherSubjects}
          onClose={handleCloseSubjectListPopup}
        />
      )}
      {isDeletePopupOpen && teacherToDelete && (
        <DeleteTeacherConfirmationPopup
          onCancel={() => setDeletePopupOpen(false)}
          onDelete={handleDeleteTeacher}
        />
      )}
    </div>
  );
}

export default TeacherSegmentScreen;
