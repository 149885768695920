import React from "react";

function NameInputs({ firstName, middleName, lastName, onChange }) {
  return (
    <div className="name-inputs">
      <div className="input-group">
        <label htmlFor="firstName">First Name *</label>
        <input
          type="text"
          id="firstName"
          name="firstName"
          value={firstName}
          onChange={onChange}
          required
        />
      </div>
      <div className="input-group">
        <label htmlFor="middleName">Middle Name</label>
        <input
          type="text"
          id="middleName"
          name="middleName"
          value={middleName}
          onChange={onChange}
        />
      </div>
      <div className="input-group">
        <label htmlFor="lastName">Last Name *</label>
        <input
          type="text"
          id="lastName"
          name="lastName"
          value={lastName}
          onChange={onChange}
          required
        />
      </div>
    </div>
  );
}

export default NameInputs;
