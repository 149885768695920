import React from "react";

function SaveButton({ handleClick }) {
  return (
    <div className="save-button" onClick={handleClick}>
      <i className="fas fa-save"></i> Save
    </div>
  );
}

export default SaveButton;
