import { React, useState } from "react";
import QuickLinksCard from "./student_segment_screen/screen_components/QuickLinksCard";
import studentData from "../../data/DummyStudentData";
import AgeChartBar from "./student_segment_screen/screen_components/AgeChartBar";
import GenderRatioChart from "./student_segment_screen/screen_components/GenderRatioChart";
import AllStudentsComponent from "./student_segment_screen/AllStudentsComponent";
import CreateNewStudentComponent from "./student_segment_screen/CreateNewStudentComponent";

function StudentSegmentScreen() {
  const [displayViewAllStudents, setDisplayViewAllStudents] = useState(false);
  const [displayCreateNewStudent, setDisplayCreateNewStudent] = useState(false);

  const quickLinksData = [
    {
      title: "View all students",
      id: 1,
      icon: "fas fa-users",
      handleQuicktap: () => {
        setDisplayViewAllStudents(true);
      },
    },
    {
      title: "Create new student",
      id: 2,
      icon: "fas fa-user-plus",
      handleQuicktap: () => {
        setDisplayCreateNewStudent(true);
      },
    },
  ];

  const handleBackButtonClick = () => {
    setDisplayViewAllStudents(false);
    setDisplayCreateNewStudent(false);
  };

  if (displayViewAllStudents) {
    return (
      <div>
        <AllStudentsComponent
          handleBackButtonClick={handleBackButtonClick}
          studentData={studentData}
        />
      </div>
    );
  }

  if (displayCreateNewStudent) {
    return (
      <div>
        <CreateNewStudentComponent
          handleBackButtonClick={handleBackButtonClick}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="quick-links">
        <h2 className="quick-links-heading">Actions</h2>
        <div className="quick-links-container">
          {quickLinksData.map((quickLink) => (
            <QuickLinksCard
              key={quickLink.id}
              title={quickLink.title}
              icon={quickLink.icon}
              handleQuicktap={quickLink.handleQuicktap}
            />
          ))}
        </div>
      </div>
      <div className="charts">
        <AgeChartBar studentData={studentData} />
        <GenderRatioChart studentData={studentData} />
      </div>
    </div>
  );
}

export default StudentSegmentScreen;
