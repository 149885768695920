import React, { useState } from "react";
import StudentDetailsPage from "./StudentDetailsPage";

function StudentCard({ student }) {
  const {
    firstName,
    middleName,
    lastName,
    displayPicture,
    classLevel,
    classArm,
    age,
    gender,
    id,
  } = student;

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const handleCardClick = () => {
    setIsDetailsOpen(true);
  };

  const handleCloseDetails = () => {
    setIsDetailsOpen(false);
  };

  return (
    <>
      <div className="student-card" onClick={handleCardClick}>
        <div className="student-avatar">
          <img src={displayPicture} alt="Student Avatar" />
        </div>
        <div className="student-details">
          <div className="st-st-div">
            <h4 className="student-name">{`${lastName} ${firstName} ${middleName}`}</h4>
            <p className="student-id">ID: {id}</p>
          </div>
          <div className="student-info">
            <p className="student-gender">Gender : {gender}</p>
            <p className="student-age">Age : {age}</p>
          </div>
          <div className="student-class">
            <p className="student-class-level">Class : {classLevel}</p>
            <p className="student-class-arm">{classArm}</p>
          </div>
        </div>
      </div>
      {isDetailsOpen && (
        <div className="student-details-overlay">
          <StudentDetailsPage student={student} onClose={handleCloseDetails} />
        </div>
      )}
    </>
  );
}

export default StudentCard;
