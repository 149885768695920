import React, { useState } from "react";
import AvatarUpload from "../student_segment_screen/screen_components/AvatarUpload";

function CreateNewTeacher({ onClose }) {
  const [formData, setFormData] = useState({
    avatarURL: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    gender: "",
    phoneNumber: "",
    emailAddress: "",
    address: "",
    nextOfKinName: "",
    nextOfKinGender: "",
    nextOfKinRelationship: "",
    nextOfKinPhoneNumber: "",
    nextOfKinAddress: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add logic to handle the form submission here
    console.log(formData);
    onClose();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAvatarChange = (imageDataURL) => {
    setFormData({ ...formData, avatarURL: imageDataURL });
  };

  return (
    <div className="create-teacher-popup">
      <div className="create-teacher-content">
        <i className="fas fa-times" onClick={onClose}></i>
        <h2>Create New Teacher</h2>
        <form onSubmit={handleSubmit}>
          <AvatarUpload
            imageURL={formData.avatarURL}
            setImageURL={(url) => setFormData({ ...formData, avatarURL: url })}
            handleAvatarChange={handleAvatarChange}
          />

          <label>
            First Name:
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Middle Name:
            <input
              type="text"
              name="middleName"
              value={formData.middleName}
              onChange={handleChange}
            />
          </label>

          <label>
            Last Name:
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Date of Birth:
            <input
              type="date"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Gender:
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </label>

          <label>
            Phone Number:
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Email Address:
            <input
              type="email"
              name="emailAddress"
              value={formData.emailAddress}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Address:
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Next of Kin Name:
            <input
              type="text"
              name="nextOfKinName"
              value={formData.nextOfKinName}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Next of Kin Gender:
            <select
              name="nextOfKinGender"
              value={formData.nextOfKinGender}
              onChange={handleChange}
              required
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </label>

          <label>
            Next of Kin Relationship:
            <input
              type="text"
              name="nextOfKinRelationship"
              value={formData.nextOfKinRelationship}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Next of Kin Phone Number:
            <input
              type="tel"
              name="nextOfKinPhoneNumber"
              value={formData.nextOfKinPhoneNumber}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Next of Kin Address:
            <input
              type="text"
              name="nextOfKinAddress"
              value={formData.nextOfKinAddress}
              onChange={handleChange}
              required
            />
          </label>

          <div className="buttons">
            <button type="submit">Save</button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateNewTeacher;
