import React, { useState } from "react";
import AvatarUpload from "./AvatarUpload";

function StudentDetailsPage({ student, onClose }) {
  const [firstName, setFirstName] = useState(student.firstName);
  const [middleName, setMiddleName] = useState(student.middleName);
  const [lastName, setLastName] = useState(student.lastName);
  const [studentEmail, setStudentEmail] = useState("example@mail.com");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState(student.gender);
  const [address, setAddress] = useState("");
  const [classLevel, setClassLevel] = useState(student.classLevel);

  const [guardianName, setGuardianName] = useState("");
  const [guardianAddress, setGuardianAddress] = useState("");
  const [guardianPhoneNumber, setGuardianPhoneNumber] = useState("");
  const [guardianEmail, setGuardianEmail] = useState("");
  const [guardianGender, setGuardianGender] = useState("");
  const [guardianRelationship, setGuardianRelationship] = useState("");
  const [guardianOccupation, setGuardianOccupation] = useState("");

  const [isPersonalEditMode, setIsPersonalEditMode] = useState(false);
  const [isGuardianEditMode, setIsGuardianEditMode] = useState(false);
  const [imageURL, setImageURL] = useState(student.displayPicture);
  const [avatar, setAvatar] = useState(null);
  const handleAvatarChange = (selectedAvatar) => {
    setAvatar(selectedAvatar);
  };

  const handlePersonalEditClick = () => {
    setIsPersonalEditMode(!isPersonalEditMode);
  };

  const handlePersonalSaveClick = () => {
    console.log("Saving changes...");
    setIsPersonalEditMode(false);
  };

  const handleGuardianEditClick = () => {
    setIsGuardianEditMode(!isGuardianEditMode);
  };

  const handleGuardianSaveClick = () => {
    console.log("Saving changes...");
    setIsGuardianEditMode(false);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleClassLevelChange = (e) => {
    setClassLevel(e.target.value);
  };

  // const handleInputChange = (field, value) => {
  //   console.log(`Field: ${field}, Value: ${value}`);
  // };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleMiddleNamechange = (e) => {
    setMiddleName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleStudentEmailChange = (e) => {
    setStudentEmail(e.target.value);
  };

  const handleDateOfBirthChange = (e) => {
    setDateOfBirth(e.target.value);
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleGuardianNameChange = (e) => {
    setGuardianName(e.target.value);
  };

  const handleGuardianAddressChange = (e) => {
    setGuardianAddress(e.target.value);
  };

  const handleGuardianPhoneNumberChange = (e) => {
    setGuardianPhoneNumber(e.target.value);
  };

  const handleGuardianEmailChange = (e) => {
    setGuardianEmail(e.target.value);
  };

  const handleGuardianGenderChange = (e) => {
    setGuardianGender(e.target.value);
  };

  const handleGuardianRelationshipChange = (e) => {
    setGuardianRelationship(e.target.value);
  };

  const handleGuardianOccupationChange = (e) => {
    setGuardianOccupation(e.target.value);
  };

  return (
    <div className="popup-overlay2">
      <div className="student-details-popup">
        <div className="student-details-header">
          <AvatarUpload
            imageURL={imageURL}
            setImageURL={setImageURL}
            handleAvatarChange={handleAvatarChange}
          />
          <div className="mx-2 avatar-save">
            <i className="fas fa-save"></i>
          </div>

          <div className="student-name mx-2">
            <h4>{`${student.lastName} ${student.firstName} ${student.middleName}`}</h4>
            <p>ID: {student.id}</p>
          </div>
          <div className="close-icon" onClick={onClose}>
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="student-details-content">
          <div className="student-info-section">
            <h4>Personal Information</h4>
            <div className="info-field">
              <label>First Name:</label>
              <input
                type="text"
                value={firstName}
                disabled={!isPersonalEditMode}
                onChange={handleFirstNameChange}
              />
            </div>
            <div className="info-field">
              <label>Middle Name:</label>
              <input
                type="text"
                value={middleName}
                disabled={!isPersonalEditMode}
                onChange={handleMiddleNamechange}
              />
            </div>
            <div className="info-field">
              <label>Last Name:</label>
              <input
                type="text"
                value={lastName}
                disabled={!isPersonalEditMode}
                onChange={handleLastNameChange}
              />
            </div>
            <div className="info-field">
              <label>Student Email:</label>
              <input
                type="email"
                value={studentEmail}
                disabled={!isPersonalEditMode}
                onChange={handleStudentEmailChange}
              />
            </div>
            <div className="info-field">
              <label>Date of Birth:</label>
              <input
                type="date"
                value={dateOfBirth}
                disabled={!isPersonalEditMode}
                onChange={handleDateOfBirthChange}
              />
            </div>
            <div className="info-field">
              <label>Gender:</label>
              <select
                value={gender}
                disabled={!isPersonalEditMode}
                onChange={handleGenderChange}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div className="info-field">
              <label>Class Level:</label>
              <select
                value={classLevel}
                disabled={!isPersonalEditMode}
                onChange={handleClassLevelChange}
              >
                <option value="JSS1">JSS1</option>
                <option value="JSS2">JSS2</option>
                <option value="JSS3">JSS3</option>
                <option value="SSS1">SSS1</option>
                <option value="SSS2">SSS2</option>
                <option value="SSS3">SSS3</option>
              </select>
            </div>
            <div className="info-field">
              <label>Address:</label>
              <input
                type="text"
                value={address}
                disabled={!isPersonalEditMode}
                onChange={handleAddressChange}
              />
            </div>{" "}
            <div className="edit-save-btn">
              {isPersonalEditMode ? (
                <button onClick={handlePersonalSaveClick}>Save</button>
              ) : (
                <button onClick={handlePersonalEditClick}>Edit</button>
              )}
            </div>
          </div>
          <div className="guardian-info-section">
            <h4>Guardian Information</h4>
            <div className="info-field">
              <label>Guardian Name:</label>
              <input
                type="text"
                value={guardianName}
                disabled={!isGuardianEditMode}
                onChange={handleGuardianNameChange}
              />
            </div>
            <div className="info-field">
              <label>Guardian Address:</label>
              <input
                type="text"
                value={guardianAddress}
                disabled={!isGuardianEditMode}
                onChange={handleGuardianAddressChange}
              />
            </div>
            <div className="info-field">
              <label>Guardian Phone Number:</label>
              <input
                type="text"
                value={guardianPhoneNumber}
                disabled={!isGuardianEditMode}
                onChange={handleGuardianPhoneNumberChange}
              />
            </div>
            <div className="info-field">
              <label>Guardian Email:</label>
              <input
                type="email"
                value={guardianEmail}
                disabled={!isGuardianEditMode}
                onChange={handleGuardianEmailChange}
              />
            </div>
            <div className="info-field">
              <label>Guardian Gender:</label>
              <select
                value={guardianGender}
                disabled={!isGuardianEditMode}
                onChange={handleGuardianGenderChange}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div className="info-field">
              <label>Guardian Relationship:</label>
              <select
                value={guardianRelationship}
                disabled={!isGuardianEditMode}
                onChange={handleGuardianRelationshipChange}
              >
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                <option value="Uncle">Uncle</option>
                <option value="Aunt">Aunt</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className="info-field">
              <label>Guardian Occupation:</label>
              <input
                type="text"
                value={guardianOccupation}
                disabled={!isGuardianEditMode}
                onChange={handleGuardianOccupationChange}
              />
            </div>
            <div className="edit-save-btn">
              {isGuardianEditMode ? (
                <button onClick={handleGuardianSaveClick}>Save</button>
              ) : (
                <button onClick={handleGuardianEditClick}>Edit</button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentDetailsPage;
