import Segment from "./Segment";
import StudentSegmentScreen from "../screens/segments/StudentSegmentScreen";
import TeacherSegmentScreen from "../screens/segments/TeacherSegmentScreen";
import HomeSegmentScreen from "../screens/segments/HomeSegmentScreen";
import AdminSegmentScreen from "../screens/segments/AdminSegmentScreen";
import SubjectSegmentScreen from "../screens/segments/SubjectSegmentScreen";
import ClassesSegmentScreen from "../screens/segments/ClassesSegmentScreen";

const segmentsList = [
  new Segment("Home", HomeSegmentScreen, "home", "fas fa-home"),
  new Segment(
    "Students",
    StudentSegmentScreen,
    "student",
    "fas fa-user-graduate"
  ),
  new Segment(
    "Teachers",
    TeacherSegmentScreen,
    "teacher",
    "fas fa-chalkboard-teacher"
  ),
  new Segment("Admins", AdminSegmentScreen, "admins", "fas fa-user-cog"),
  new Segment("Subjects", SubjectSegmentScreen, "subject", "fas fa-book"),
  new Segment("Classes", ClassesSegmentScreen, "classes", "fas fa-users"),
  new Segment(
    "Time Table",
    AdminSegmentScreen,
    "timetable",
    "far fa-calendar-alt"
  ),
  new Segment(
    "Test Management",
    AdminSegmentScreen,
    "testmanagement",
    "fas fa-clipboard-check"
  ),
  new Segment("Results", AdminSegmentScreen, "results", "fas fa-medal"),
  new Segment(
    "School Calendar",
    AdminSegmentScreen,
    "schoolcalendar",
    "fas fa-calendar-day"
  ),
  new Segment(
    "Admissions",
    AdminSegmentScreen,
    "admissions",
    "fas fa-user-plus"
  ),
  new Segment(
    "Grade System",
    AdminSegmentScreen,
    "gradesystem",
    "fas fa-chart-bar"
  ),
  new Segment(
    "Payment System",
    AdminSegmentScreen,
    "paymentsystem",
    "fas fa-credit-card"
  ),
  new Segment(
    "Notifications",
    AdminSegmentScreen,
    "notifications",
    "fas fa-bell"
  ),
  new Segment("Settings", AdminSegmentScreen, "settings", "fas fa-cog"),
];

export default segmentsList;
