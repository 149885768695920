import React from "react";

function QuickLinksCard({ title, icon, handleQuicktap }) {
  return (
    <div className="quick-links-card" onClick={handleQuicktap}>
      <i className={icon}></i>
      <span>{title}</span>
    </div>
  );
}

export default QuickLinksCard;
