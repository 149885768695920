import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import segmentsList from "../models/SegmentsList";
import Topbar from "../components/Topbar";
import "./MainScreen.css";

function MainScreen() {
  const [selectedSegment, setSelectedSegment] = useState(
    segmentsList.find((segment) => segment.id === "home")
  );

  const handleSegmentClick = (segment) => {
    setSelectedSegment(segment);
  };

  function handleHomeClick() {
    setSelectedSegment(segmentsList.find((segment) => segment.id === "home"));
  }

  function handleNotificationsClick() {
    setSelectedSegment(
      segmentsList.find((segment) => segment.id === "notifications")
    );
  }

  function handleSettingsClick() {
    setSelectedSegment(
      segmentsList.find((segment) => segment.id === "settings")
    );
  }

  function handleStudentCardClick() {
    setSelectedSegment(
      segmentsList.find((segment) => segment.id === "student")
    );
  }

  function handleTeacherNumberClick() {
    setSelectedSegment(
      segmentsList.find((segment) => segment.id === "teacher")
    );
  }

  function handleAdminNumberClick() {
    setSelectedSegment(segmentsList.find((segment) => segment.id === "admins"));
  }

  function handleSubjectsNumberClick() {
    setSelectedSegment(
      segmentsList.find((segment) => segment.id === "subject")
    );
  }

  function handleClassesNumberClick() {
    setSelectedSegment(
      segmentsList.find((segment) => segment.id === "classes")
    );
  }

  function handleCardClick(id) {
    setSelectedSegment(
      segmentsList.find((segment) => segment.id === "student")
    );
  }

  return (
    <div>
      <Sidebar
        handleSegmentClick={handleSegmentClick}
        selectedSegment={selectedSegment}
      />
      <Topbar
        segmentTitle={selectedSegment && selectedSegment.title}
        segmentIcon={selectedSegment && selectedSegment.icon}
        handleHomeClick={handleHomeClick}
        handleNotificationsClick={handleNotificationsClick}
        handleSettingsClick={handleSettingsClick}
      />
      <div
        className="main-content"
        style={{ marginLeft: "20%", padding: "20px" }}
      >
        {/* Content of the main screen */}
        {selectedSegment && selectedSegment.segmentScreen && (
          <selectedSegment.segmentScreen
            onStudentNumberCardClick={handleStudentCardClick}
            onTeacherNumberCardClick={handleTeacherNumberClick}
            onAdminNumberCardClick={handleAdminNumberClick}
            onSubjectNumberCardClick={handleSubjectsNumberClick}
            onClassesNumberCardClick={handleClassesNumberClick}
          />
        )}
      </div>
    </div>
  );
}

export default MainScreen;
