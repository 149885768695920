import { React } from "react";
import ContentCard from "./home_segment_screen/screen_components/ContentCard";
import StudentNumberChart from "./home_segment_screen/screen_components/StudentNumberChart";
import studentData from "../../data/DummyStudentData";

function HomeSegmentScreen({
  onStudentNumberCardClick,
  onTeacherNumberCardClick,
  onAdminNumberCardClick,
  onSubjectNumberCardClick,
  onClassesNumberCardClick,
}) {
  const classData = [
    {
      class: "JSS1",
      totalStudents: studentData.filter(
        (student) => student.classLevel === "JSS1"
      ).length,
    },
    {
      class: "JSS2",
      totalStudents: studentData.filter(
        (student) => student.classLevel === "JSS2"
      ).length,
    },
    {
      class: "JSS3",
      totalStudents: studentData.filter(
        (student) => student.classLevel === "JSS3"
      ).length,
    },
    {
      class: "SSS1",
      totalStudents: studentData.filter(
        (student) => student.classLevel === "SSS1"
      ).length,
    },
    {
      class: "SSS2",
      totalStudents: studentData.filter(
        (student) => student.classLevel === "SSS2"
      ).length,
    },
    {
      class: "SSS3",
      totalStudents: studentData.filter(
        (student) => student.classLevel === "SSS3"
      ).length,
    },
  ];

  const totalStudents = classData.reduce(
    (sum, data) => sum + data.totalStudents,
    0
  );

  const studentNumber = studentData.length;
  const teacherNumber = 59;
  const adminNumber = 7;
  const classesNumber = 18;
  const subjectNumber = 37;
  const contentData = [
    {
      icon: <i className="fas fa-user-graduate"></i>,
      text: "Total number of active students : ",
      subText: totalStudents,
      id: "studentNumber",
    },
    {
      icon: <i className="fas fa-chalkboard-teacher"></i>,
      text: "Total number of Teachers : ",
      subText: teacherNumber,
      id: "teacherNumber",
    },
    {
      icon: <i className="fas fa-user-cog"></i>,
      text: "Number of Admins : ",
      subText: adminNumber,
      id: "adminNumber",
    },
    {
      icon: <i className="fas fa-users"></i>,
      text: "Total number of Classes : ",
      subText: classesNumber,
      id: "classesNumber",
    },
    {
      icon: <i className="fas fa-book"></i>,
      text: "Number of Subjects taught : ",
      subText: subjectNumber,
      id: "subjectNumber",
    },
  ];
  const semesterTxt = "2023/2024";
  const termTxt = "First Term";
  const semesterText = `Current Session : ${semesterTxt}`;
  const termText = `Current Term : ${termTxt}`;

  function handleCardClick(id) {
    console.log(`Clicked on ${id}`);
    if (id === "studentNumber") {
      onStudentNumberCardClick();
    }
    if (id === "teacherNumber") {
      onTeacherNumberCardClick();
    }
    if (id === "adminNumber") {
      onAdminNumberCardClick();
    }
    if (id === "subjectNumber") {
      onSubjectNumberCardClick();
    }
    if (id === "classesNumber") {
      onClassesNumberCardClick();
    }
  }

  return (
    <div>
      <div className="larger-card-holder">
        <div className="larger-card">
          <div className="l-card-icon mx-1">
            <i className="fas fa-graduation-cap"></i>
          </div>
          <div className="mx-2">{semesterText}</div>
        </div>
        <div className="larger-card">
          <div className="l-card-icon mx-1">
            <i className="fas fa-clock"></i>
          </div>
          <div className="mx-2">{termText}</div>
        </div>
      </div>
      <div className="grid-container">
        {contentData.map((content, index) => (
          <div key={index} className="grid-item">
            <ContentCard
              icon={content.icon}
              text={content.text}
              subText={content.subText}
              handleCardClick={() => handleCardClick(content.id)}
            />
          </div>
        ))}
      </div>
      <div>
        <StudentNumberChart classData={classData} />
      </div>
    </div>
  );
}

export default HomeSegmentScreen;
