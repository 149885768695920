import React from "react";

function TeacherCard({ teacher, onEdit, onViewSubjects, onDelete }) {
  const { id, firstname, middlename, lastname, displayPicture, gender } =
    teacher;

  return (
    <div className="teacher-content-card">
      <div className="teacher-icons">
        <i
          className="fas fa-pencil-alt teacher-icon"
          title="Edit Details"
          onClick={onEdit}
        ></i>
        <i
          className="fas fa-eye teacher-icon"
          title="View Subjects"
          onClick={onViewSubjects}
        ></i>
        <i
          className="fas fa-trash-alt teacher-icon"
          title="Delete Teacher"
          onClick={onDelete}
        ></i>
      </div>
      <div className="teacher-avatar">
        <img src={displayPicture} alt={`${firstname} ${lastname}`} />
      </div>
      <div className="teacher-details">
        <p className="teacher-name">{`${firstname} ${middlename} ${lastname}`}</p>
        <p className="teacher-gender">{gender}</p>
      </div>
    </div>
  );
}

export default TeacherCard;
