const adminData = [
  {
    id: 1,
    name: "John Doe",
    image: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
  },
  {
    id: 2,
    name: "Jane Smith",
    image: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
  },
  {
    id: 3,
    name: "Anna Johnson",
    image: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
  },
  {
    id: 4,
    name: "Brian Wilson",
    image: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
  },
  {
    id: 5,
    name: "Catherine Hall",
    image: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
  },
  {
    id: 6,
    name: "David Jones",
    image: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
  },
  {
    id: 7,
    name: "Eva Green",
    image: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
  },
];

export default adminData;
